<template>
  <AppCard>
    <AppCardImageTitle
      :src="require('@galileo/assets/images/illustrations/success.svg')"
      alt="Success"
      :title="$t('PageSendMoneyVerifyIdentitySuccess.Title').value"
    >
      <p>
        {{ $t('PageSendMoneyVerifyIdentitySuccess.Description').value }}
      </p>
    </AppCardImageTitle>
    <template #footer>
      <AppCardFooter>
        <AppButton analytics-name="send-verify-identity-success-done" @click="done">{{
          $t('PageSendMoneyVerifyIdentitySuccess.ButtonDone').value
        }}</AppButton>
      </AppCardFooter>
    </template>
  </AppCard>
</template>

<script>
import { AppButton, AppCard, AppCardFooter, AppCardImageTitle } from '@oen.web.vue2/ui'

import { uuseI18nStore, seSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneyVerifyIdentitySuccess',
  components: {
    AppCardImageTitle,
    AppButton,
    AppCard,
    AppCardFooter,
  },
  setup() {
    const { $t } = useI18nStore()

    const done = () => {
      useSendMoneyStore().processOrder({ replacePage: true })
    }
    return {
      done,
      $t,
    }
  },
}
</script>

<style scoped></style>
